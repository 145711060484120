import { Container, Row, Col } from "react-bootstrap";
import '../style.css';
import Perso_1 from "../../../utils/images/perso_1.svg";
import Perso_2 from "../../../utils/images/perso_2.svg";
import BandeauPC from "../../../utils/images/bandeauPC.svg";
import BackgroundGradiant from "../../../utils/images/gard2.png";
import {RenderStars} from "../../../components/RenderStars";
import {MissionContainer} from "./MissionContainer";
import {FeatbackContainer} from "./FeatbackComponents/FeatbackContainer";

export default function LandingContainer()   {
    return (
        <Container>
            <div style={{height: "7vh"}}></div>
            <Row className='JobbeurNewTitleContainer'>
                <Col md={{ span: 6, offset: 1 }} className='JobbeurNewTitle'>
                    {`Trouve tes missions 
                    Freelance sur Jobbeur`}
                </Col>
                <Col>
                    <img className="background-image" style={{marginTop: '-5vh'}} id={'Tel'} src={Perso_1} alt="perso_1" />
                </Col>
            </Row>
            <Row className='JobbeurNewTitleContainer'>
                <Col md={{ span: 7, offset: 1 }}>
                    {'Jobbeur t’offre l’opportunité de trouver des missions freelance adaptés à tes compétences. Retrouvez des offres de missions de création de site, création de logo etc.'}
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 5, offset: 1 }}>
                    <button className='JobbeurButtonSmall'>Je trouve une mission freelance</button>
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 3, offset: 1 }}>
                    {RenderStars(4)} {' 4/5'}
                </Col>
            </Row>

            <Row className='JobbeurNewTitleContainer'>
                <Col md={{ span: 7, offset: 3 }} className='JobbeurFullBlueTitle'>
                    {'Plus de 1000 missions adaptées à tes compétences'}
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 11, offset: 1 }}>
                    <MissionContainer></MissionContainer>
                </Col>
            </Row>

            <Row className='JobbeurNewTitleContainer'>
                <Col md={{ span: 6, offset: 1 }} className='JobbeurNewTitle-1'>
                    {`Ce qu’ils pensent de nous`}
                </Col>
                <Col>
                    <img className="background-image" style={{marginTop:"-10%"}} id={'Tel'} src={Perso_2} alt="perso_1" />
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 7, offset: 1 }}>
                    {'Votre avis compte pour nous !'}
                    <br/>
                    {'Jobbeur est à l’écoute des Startups du digital pour leur offrir l’opportunité de développer leur talent.'}
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 11, offset: 1 }}>
                    <FeatbackContainer></FeatbackContainer>
                </Col>
            </Row>

            <Row className='JobbeurNewTitleContainer'>
                <Col md={9} className='JobbeurFullBlueTitle'>
                    {'Choisis ta mission dès maintenant'}
                </Col>
            </Row>
            <Row >
                <Col md={{span: 7, offset: 1}} className='JobbeurFullBlueTitle align-text-left mediumText'>
                    {'L’app qui t’aide à trouver le job étudiant qui te correspond.'}
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 3, offset: 1 }}>
                    <button className='JobbeurButtonSmall'>Je m’inscris</button>
                </Col>
            </Row>
        </Container>
    );
}
