import "./App.css";
// import bootstrap css
import "bootstrap/dist/css/bootstrap.min.css";
import React from 'react';
import { render, screen } from '@testing-library/react';
import { Home } from "./pages/Home";
import { Login } from "./pages/Login";
import { Sign } from "./pages/Sign";
import { NotFound } from "./pages/NotFound";
import { Dashboard } from "./pages/Dashboard";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { NavBar } from "./components/NavBar";
import { RouterPath } from "./app/router-path";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { useEffect } from "react";
import {
  getSession,
  setSession,
  getResetPasswordStatus,
} from "./actions/session";
import { getProfileInformation } from "./actions/user-api";
import { supabase } from "./app/supabaseClient";
import { passwordRecoveryUpdated } from "./features/session-slice";

function App() {
  const dispatch = useAppDispatch();
  const session = useAppSelector((state) => state.session.session);

  useEffect(() => {
    let serviceWorker: ServiceWorkerRegistration | null = null;

    Notification.requestPermission().then((result) => {
        console.log(result);
    });

    const registerServiceWorker = async () => {
      if ('serviceWorker' in navigator) {
        try {
          const registration = await navigator.serviceWorker.register('service-worker.js');
          console.log('Service worker registered');
          serviceWorker = registration;
        } catch (error) {
          console.log('Failed to register service worker:', error);
        }
      }
    };

    const unregisterServiceWorker = async () => {
      if (serviceWorker) {
        try {
          await serviceWorker.unregister();
          console.log('Service worker unregistered');
        } catch (error) {
          console.log('Failed to unregister service worker:', error);
        }
      }
    };

    registerServiceWorker();

    // Unregister service worker when the component is unmounted
    return () => {
      unregisterServiceWorker();
    };
  }, []);

  // Get session on component mount
  useEffect(() => {
    dispatch(getSession());
    supabase.auth.onAuthStateChange((_event, session) => {
      dispatch(setSession(session));
      if (_event === "PASSWORD_RECOVERY") {
        dispatch(passwordRecoveryUpdated(true));
        localStorage.setItem("passwordRecovery", "true");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If Session changes, fetch user information
  useEffect(() => {
    if (session) {
      dispatch(getProfileInformation(session));
      dispatch(getResetPasswordStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, dispatch]);
  return (
      <>
        <NavBar></NavBar>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path={RouterPath.home} element={<Home />} />
            <Route path={RouterPath.login} element={<Login />} />
            <Route path={RouterPath.sign} element={<Sign />} />
            <Route path={RouterPath.dashboard} element={<Dashboard />} />
            {/*<Route path={RouterPath.map} element={<Map />} />

            <Route
                path={RouterPath.passwordRecovery}
                element={<PasswordRecoveryPage />}
            />
            {session ? (
                <>
                  <Route path={RouterPath.pokedex} element={<NotFound />} />

                  <Route path={RouterPath.capture} element={<Capture />} />
                  <Route path={RouterPath.account} element={<Account />} />
                  <Route path={RouterPath.profile} element={<Account />} />

                  <Route path={RouterPath.garden} element={<Garden />} />
                  <Route path={RouterPath.battle} element={<Battle />} />
                </>
            ) : (
                <></>
            )}*/}
          </Routes>
        </BrowserRouter>
      </>
  );
}

export default App;
