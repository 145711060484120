import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import BasicForm from "../BasicForm";
import {useEffect, useState} from "react";
import {supabase} from "../../../../app/supabaseClient";


export default function NewMissionModal(props: any) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Crée une nouvelle mission
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BasicForm/>
      </Modal.Body>
    </Modal>
  );
}
