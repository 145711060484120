import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {Col, Container, FloatingLabel, Row} from "react-bootstrap";
import {BaseSign} from "./BaseSign";
import React, {useState} from "react";
import {FieldValues, useForm} from "react-hook-form";
import {supabase} from "../../../app/supabaseClient";
import {Link} from "react-router-dom";

interface Session {
    user: {
        id: number;
        email: string;
    };
}

interface EtuRegisterProps {
    session: Session;
}
export function EtuRegister({session}: EtuRegisterProps) : JSX.Element {

    const [civility, setCivility] = useState<string>('');
    const [nom, setNom] = useState<string>('');
    const [prenom, setPrenom] = useState<string>('');
    const [email, setEmail] = useState<string>(session.user.email || '');
    const [tel, setTel] = useState<string>('');
    const [ville, setVille] = useState<string>('');
    const [poste, setPoste] = useState<bigint>();
    const [domaine, setDomaine] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const Register = async (
        name: string,
    ) => {
        await supabase.rpc('insert_etu_profile', {
            id: session.user.id,
            username: name,
            role: 'etudiant',
        });
    };

    return (
        <>
            <Container className="registerCenterForm" style={{marginTop:"-25vh"}}>
                <Form>
                    <Row xs={6} lg={12}>
                        <Col lg={{ span: 10, offset: 1 }}>
                            <div className="JobbeurText"  style={{textAlign:"center", color:"#000"}}>
                                Créer votre compte
                            </div>
                        </Col>
                    </Row>
                    <Row xs={6} lg={12}>
                        <Col xs={4}  lg={{span:10, offset:1}}>
                            <FloatingLabel controlId="floatingSelect" label="Civilité">
                                <Form.Select aria-label="Floating label select example"
                                             value={civility}
                                             onChange={(e) => setCivility(e.target.value)}>
                                    <option value="3">Ne pas préciser</option>
                                    <option value="1">Homme</option>
                                    <option value="2">Femme</option>
                                </Form.Select>
                            </FloatingLabel>
                            <br />
                        </Col>
                    </Row>
                    <Row xs={6} lg={12}>
                        <Col xs={4}  lg={{span:10, offset:1}}>
                            <div className="group">
                                <input id='nomInput' type="text" required style={{width:"100%"}}
                                       value={nom}
                                       onChange={(e) => setNom(e.target.value)}/>
                                <span className="highlight"></span>
                                <label className="JobbeurInputText">Nom</label>
                            </div>
                        </Col>
                    </Row>
                    <Row xs={6} lg={12}>
                        <Col xs={4}  lg={{span:10, offset:1}}>
                            <div className="group">
                                <input id='prenomInput' type="text" required style={{width:"100%"}}
                                       value={prenom}
                                       onChange={(e) => setPrenom(e.target.value)}/>
                                <span className="highlight"></span>
                                <label className="JobbeurInputText" style={{fontWeight:"1.7rem"}}>Prénom</label>
                            </div>
                        </Col>
                    </Row>
                    <Row xs={6} lg={12}>
                        <Col xs={4}  lg={{span:10, offset:1}}>
                            <div className="group">
                                <input id='emailInput' type="text" required style={{width:"100%"}}
                                       value={email}
                                       onChange={(e) => setEmail(e.target.value)}/>
                                <span className="highlight"></span>
                                <label className="JobbeurInputText">Email</label>
                            </div>
                        </Col>
                    </Row>
                    <Row xs={6} lg={12}>
                        <Col xs={4}  lg={{span:10, offset:1}}>
                            <div className="group">
                                <input id='telInput' type="text" required style={{width:"100%"}}
                                       value={tel}
                                       onChange={(e) => setTel(e.target.value)}/>
                                <span className="highlight"></span>
                                <label className="JobbeurInputText">N°téléphone</label>
                            </div>
                        </Col>
                    </Row>
                    <Row xs={6} lg={12}>
                        <Col lg={{span:6, offset:1}}>
                            <div className="group">
                                <input id='cityInput' type="text" required style={{width:"100%"}}
                                       value={ville}
                                       onChange={(e) => setVille(e.target.value)}/>
                                <span className="highlight"></span>
                                <label className="JobbeurInputText">Ville</label>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="group">
                                <input
                                    id='posteInput'
                                    type="text"
                                    required
                                    style={{width:"100%"}}
                                    value={poste === undefined ? '' : poste.toString()}  // Convert bigint to string for input value
                                    onChange={(e) => {
                                        const inputValue = e.target.value.trim();
                                        setPoste(inputValue === '' ? undefined : BigInt(inputValue) as bigint);
                                    }}
                                />
                                <span className="highlight"></span>
                                <label className="JobbeurInputText">Code postal</label>
                            </div>
                        </Col>
                    </Row>
                    <Row xs={6} lg={12}>
                        <Col xs={4}  lg={{span:10, offset:1}}>
                            <FloatingLabel controlId="floatingSelect" label="Domaines d'étude">
                                <Form.Select aria-label="Domaines d'étude"
                                             value={domaine}
                                             onChange={(e) => setDomaine(e.target.value)}>
                                    <option value="1">Maketing digital</option>
                                    <option value="2">Développeur web</option>
                                    <option value="3">UI/UX</option>
                                    <option value="4">Directeur artistique</option>
                                    <option value="5">Graphiste</option>
                                    <option value="6">Motion designer</option>
                                    <option value="7">Développeur mobile</option>
                                </Form.Select>
                            </FloatingLabel>
                            <br />
                        </Col>
                    </Row>
                    {/*<Row xs={6} lg={12}>
                        <Col xs={4}  lg={{span:10, offset:1}}>
                            <div className="group">
                                <input id='pwdInput' type="password" required style={{width:"100%"}}
                                       value={password}
                                       onChange={(e) => setPassword(e.target.value)}/>
                                <span className="highlight"></span>
                                <label className="JobbeurInputText">Mot de passe</label>
                            </div>
                        </Col>
                    </Row>*/}
                    {/*                    <Row xs={6} lg={12}>
                        <Col xs={4}  lg={{span:10, offset:1}}>
                            <div className="group">
                                <input id='pwdInput' type="password" required style={{width:"100%"}}/>
                                <span className="highlight"></span>
                                <label className="JobbeurInputText">Mot de passe</label>
                            </div>
                        </Col>
                    </Row>*/}
                </Form>
                <Row xs={6} lg={12}>
                    <Col xs={6}  lg={{span:8, offset:4}}>
                        <Link to="/dashboard">
                            <button className="JobbeurButtonClassicSmall" onClick={() => Register(nom)}>Créer un compte étudiant</button>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
