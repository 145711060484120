import { Container, Row, Col } from "react-bootstrap";
import '../style.css';
import {Mission} from "./MissionComponents/Mission";
import { TfiCommentAlt } from "react-icons/tfi";
import { IoCodeSlash } from "react-icons/io5";
import { BiCheckShield } from "react-icons/bi";
import { PiPencilFill } from "react-icons/pi";

export function MissionContainer()   {
    return (
        <Container className='MissionsContainer'>
            <Row>
                <Col>
                    <Mission icon={<TfiCommentAlt className='MissionIcon'/>} text="Communication"></Mission>
                </Col>
                <Col>
                    <Mission icon={<IoCodeSlash className='MissionIcon'/>} text="Code"></Mission>
                </Col>
                <Col>
                    <Mission icon={<BiCheckShield className='MissionIcon'/>} text="Sécurité"></Mission>
                </Col>
                <Col>
                    <Mission icon={<PiPencilFill className='MissionIcon'/>} text="Design"></Mission>
                </Col>
            </Row>
        </Container>
    );
}
