import "./style.css";
import {LoginScreen} from "./components/LoginScreen";

export function Login() : JSX.Element {
    return (
        <>
            <LoginScreen></LoginScreen>
        </>
    );
}
