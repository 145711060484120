import {Container, Row, Col, Button} from "react-bootstrap";
import {IconType} from "react-icons";
import {ReactNode} from "react";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";
import { BsInstagram } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa";


export function JobbeurDesci()   {
    return (
        <Container className='MissionContainer'>
            <Row>
                <Col md={3} className='JobbeurFullBlueTitle WhiteText'>
                    <div style={{marginBottom: '-5%', marginLeft: '-5%'}}>
                        {'JOBBEUR'}
                    </div>
                </Col>
                <Col md={{ span: 4, offset: 5 }} className='WhiteText'>
                    <Row>
                        {'Contactez-nous'}
                    </Row>
                    <Row>
                        {'26  Rue  de  la  Villette,  69003  Lyon'}
                    </Row>
                    <Row>
                        {'jobber@gmail.com'}
                    </Row>
                    <Row style={{marginTop: '2.5%'}}>
                        <Col>
                            <Button className='ButtonJobbeurVerySmall'>
                                <FaXTwitter className='BlueText'/>
                            </Button>
                        </Col>
                        <Col>
                            <Button className='ButtonJobbeurVerySmall'>
                                <FaFacebookF className='BlueText'/>
                            </Button>
                        </Col>
                        <Col>
                            <Button className='ButtonJobbeurVerySmall'>
                                <BsInstagram className='BlueText'/>
                            </Button>
                        </Col>
                        <Col>
                            <Button className='ButtonJobbeurVerySmall'>
                                <FaLinkedin className='BlueText'/>
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col md={4} className='WhiteText'>
                    <div>
                        {'La plateforme qui crée vos opportunités !\n' +
                            'Nous mettons en relation les startup et les étudiants qui souhaitent développer leurs compétences en travaillant auprès d’entreprises du monde du digital.\n' +
                            'Construis ton réseau au contact de professionnels de ton domaine de prédilection.\n' +
                            'N’attends pas et télécharge gratuitement Jobbeur sur tous les Appstores.'}
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
