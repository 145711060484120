import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import {FaStar} from "react-icons/fa";
import {CiStar} from "react-icons/ci";
import { CgProfile } from "react-icons/cg";
import React from "react";
import {Col, Container, Row} from "react-bootstrap";


const renderStars = (rating: number) => {
    const stars = [];
    const fullStars = Math.floor(rating);

    for (let i = 1; i <= fullStars; i++) {
        stars.push(
            <FaStar key={i} color="blue" />
        );
    }

    const remainingStars = 5 - fullStars;
    for (let i = 1; i <= remainingStars; i++) {
        stars.push(
            <CiStar key={fullStars + i} />
        );
    }

    return stars;
};
function ProfileMain() {
    return (
        <Card style={{ width: '18rem' }}>
            {/*<Card.Img variant="top" src="holder.js/100px180?text=Image cap" />*/}
            <Card.Text style={{ textAlign: 'center', marginTop: '2vh' }}>
                <CgProfile size={125} />
            </Card.Text>
            <Card.Body>
                <Card.Title style={{ textAlign: 'center' }}>Card Title</Card.Title>
                <Card.Text style={{ textAlign: 'center' }}>
                    Short Déscritpion
                </Card.Text>
                <Card.Text style={{ textAlign: 'center' }}>
                    {renderStars(4)}
                </Card.Text>
                <Card.Text style={{ textAlign: 'center' }} className={'littleGreyText'}>
                    X Avis
                </Card.Text>
                <Card.Text style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center' }}>
                    <div className={'littleGreyDiv'}></div>
                </Card.Text>
                <Card.Text style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center' }}>
                    <button className="JobbeurButtonClassicSmall">Modifier</button>
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default ProfileMain;
