import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import {supabase} from "../../../app/supabaseClient";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";


const BasicForm = () => {
    interface Session {
        user: {
            id: string | null; // Adjust this according to the actual type
            // Other properties of the user object
        };
        // Other properties of the session object
    }

    const [session, setSession] = useState<Session | null>(null);

    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session);
        });

        const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session);
        });

        return () => subscription.unsubscribe();
    }, []);

    const { Formik } = formik;

    const schema = yup.object().shape({
        name: yup.string().required(),
        dure: yup.string().required(),
        nb_candidat: yup.number().required(),
        city: yup.string().required(),
        difficulte: yup.number().required(),
        description: yup.string().required(),
        remuneration_total: yup.number().required(),
    });

    const Add_mission = async (
        name: string,
        dure: string,
        nb_candidat: number,
        city: string,
        difficulte: number,
        description: string,
        remuneration_total: number,
        startup_id?: string | null,
    ) => {
        await supabase.rpc('insert_mission', {
            name: name,
            dure: dure,
            nb_candidat: nb_candidat,
            city: city,
            difficulte: difficulte,
            description: description,
            remuneration_total: remuneration_total,
            startup_id: startup_id,
        });
    };

    return (
        <Formik
            validationSchema={schema}
            onSubmit={console.log}
            initialValues={{
                name: '',
                dure: '',
                nb_candidat: 0,
                city: '',
                difficulte: 0,
                description: '',
                remuneration_total: 0,
            }}
        >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Form.Group
                            as={Col}
                            md="4"
                            controlId="validationFormik101"
                            className="position-relative"
                        >
                            <Form.Control
                                type="text"
                                placeholder="Intitulé"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                isValid={touched.name && !errors.name}
                            />
                            <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                            as={Col}
                            md="4"
                            controlId="validationFormik102"
                            className="position-relative"
                        >
                            <Form.Select
                                name="dure"
                                value={values.dure}
                                onChange={handleChange}
                                isValid={touched.dure && !errors.dure}
                                aria-label="Default select example">
                                <option>Indéterminé</option>
                                <option value="1 mois">1 mois</option>
                                <option value="1 semaine">1 semaine</option>
                                <option value="Moins d'une semaine">Moins d'une semaine</option>
                            </Form.Select>

                            <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationFormiknb_candidat2">
                            <InputGroup hasValidation>
                                <InputGroup.Text id="inputGroupPrepend">Nombre d'étudiant</InputGroup.Text>
                                <Form.Control
                                    type="number"
                                    placeholder="Nombre de candidat"
                                    aria-describedby="inputGroupPrepend"
                                    name="nb_candidat"
                                    value={values.nb_candidat}
                                    onChange={handleChange}
                                    isInvalid={!!errors.nb_candidat}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {errors.nb_candidat}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationFormik103"
                            className="position-relative"
                        >
                            <Form.Control
                                type="text"
                                placeholder="Ville"
                                name="city"
                                value={values.city}
                                onChange={handleChange}
                                isInvalid={!!errors.city}
                            />

                            {/*<Form.Control.Feedback type="invalid" tooltip>
                                {errors.city}
                            </Form.Control.Feedback>*/}
                        </Form.Group>
                        <Form.Group
                            as={Col}
                            md="3"
                            controlId="validationFormik104"
                            className="position-relative"
                        >
                            <Form.Control
                                type="number"
                                placeholder="Difficulté"
                                name="difficulte"
                                value={values.difficulte}
                                onChange={handleChange}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                                {errors.difficulte}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationFormik105"
                            className="position-relative"
                        >
                            <Form.Control
                                as="textarea"
                                value={values.description}
                                placeholder="Description"
                                style={{ height: '100px' }}
                            />

                            {/*<Form.Control.Feedback type="invalid" tooltip>
                                {errors.description}
                            </Form.Control.Feedback>*/}
                        </Form.Group>
                    </Row>
                    {/*<Row className="mb-3">
                        <Form.Group
                            as={Col}
                            md="3"
                            controlId="validationFormik105"
                            className="position-relative"
                        >
                            <Form.Control
                                type="text"
                                placeholder="Rémunération"
                                name="Rémunération total"
                                value={values.remuneration_total}
                                onChange={handleChange}
                                isInvalid={!!errors.remuneration_total}
                            />

                            <Form.Control.Feedback type="invalid" tooltip>
                                {errors.remuneration_total}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>*/}
                    <Col xs={6}  lg={{span:3, offset:9}}>
                            <button className="JobbeurButtonClassicSmall" onClick={() => {
                                // Check if session.user.id is null before calling Add_mission
                                // @ts-ignore
                                if (session) {
                                    Add_mission(
                                        values.name,
                                        values.dure,
                                        values.nb_candidat,
                                        values.city,
                                        values.difficulte,
                                        values.description,
                                        values.remuneration_total,
                                        session.user.id
                                    );
                                }
                            }}>Valider</button>
                    </Col>
                </Form>
            )}
        </Formik>
    );
}

export default BasicForm;
