import { Container, Row, Col } from "react-bootstrap";
import {RenderStars} from "../../../../components/RenderStars";

interface FeatbackCard {
    photo: any;
    name: string;
    text: string;
    date: string;
    rating: number;
}

export function Featback(FeatbackCard: FeatbackCard)   {
    return (
        <Container className='MissionContainer'>
            <Row>
                <Col md={1}>
                    <img className="round-image" id={'P1'} src={FeatbackCard.photo} alt="photo" />
                </Col>
                <Col md={{ span: 8, offset: 2 }}>
                    <div className="JobbeurNewMediumText" style={{textAlign:"center"}}>
                        {FeatbackCard.name}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    {RenderStars(FeatbackCard.rating)} {FeatbackCard.date}
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        {FeatbackCard.text}
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
