import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { GiHamburgerMenu } from "react-icons/gi";
import filter from "../../../../utils/images/filter.png";

const options = [
    {
        scroll: false,
        backdrop: false,
    },
];

function OffCanvasExample({ ...props }) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const toggleShow = () => setShow((s) => !s);

    return (
        <>
            <Button variant="primary" onClick={toggleShow} className="me-2">
                <GiHamburgerMenu />
            </Button>
            <Offcanvas show={show} onHide={handleClose} {...props}>
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <img className="background-image Big-image Reset-margin-top"
                         style={{height: '140vh', marginLeft:'-1vw', marginTop:'0.2vh'}} id={'filter'} src={filter}
                         alt="filter"/>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default function Backdrop() {
    return (
        <>
            {options.map((props, idx) => (
                <OffCanvasExample placement={'end'} key={idx} {...props} />
            ))}
        </>
    );
}

