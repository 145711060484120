import { Container, Row, Col } from "react-bootstrap";
import '../style.css';
import Perso_1 from "../../../utils/images/perso_1.svg";
import Perso_2 from "../../../utils/images/perso_2.svg";
import BandeauPC from "../../../utils/images/bandeauPC.svg";
import BackgroundGradiant from "../../../utils/images/gard2.png";
import Needs from "../../../utils/images/needs.png";
import StartupBandeau from "../../../utils/images/StartupBandeau.png";
import {RenderStars} from "../../../components/RenderStars";
import {MissionContainer} from "./MissionContainer";
import {FeatbackContainer} from "./FeatbackComponents/FeatbackContainer";

export default function StartupLandingContainer()   {
    return (
        <Container>
            <div style={{height: "7vh"}}></div>
            <Row className='JobbeurNewTitleContainer'>
                <Col md={{ span: 6, offset: 1 }} className='JobbeurNewTitle'>
                    {`Dynamiser votre entreprise avec Jobbeur`}
                </Col>
                <Col>
                    <img className="background-image" style={{marginTop: '-5vh'}} id={'Tel'} src={Perso_1} alt="perso_1" />
                </Col>
            </Row>
            <Row className='JobbeurNewTitleContainer'>
                <Col md={{ span: 7, offset: 1 }}>
                    {'Découvrez une nouvelle façon de dynamiser votre entreprise avec les meilleurs talents de demain. Avec Jobbeur vous accédez à des services de freelance/étudiants experts dans leur domaine et prêts à réaliser vos missions.'}
                    <br/>
                    {'De la conception de sites web à la gestion de vos réseaux sociaux, nous vous mettons en relation avec les compétences dont vous avez besoin.'}
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 5, offset: 1 }}>
                    <button className='JobbeurButtonSmall'>J’ai besoin d’une compétence digitale</button>
                </Col>
            </Row>


            <Row>
                <Col md={{ span: 11, offset: 1 }}>
                    <img style={{marginTop: '15vh', marginBottom: '20vh'}} id={'Needs'} src={Needs}
                         alt="Needs"/>
                </Col>
            </Row>
        </Container>
    );
}
