import { Container, Row, Col } from "react-bootstrap";
import {Featback} from "./Featback";
import P1 from "../../../../utils/images/p1.png";
import P2 from "../../../../utils/images/p2.png";
import P3 from "../../../../utils/images/p3.png";
import P4 from "../../../../utils/images/p4.png";

export function FeatbackContainer()   {
    return (
        <Container className='MissionsContainer'>
            <Row>
                <Col>
                    <Featback
                        date={'11/12/2023'}
                        text={'“Enfin un moyen simple et efficace de gagner en expérience et de développé son réseau”'}
                        name={'Ayan Adélaïde'}
                        rating={5}
                        photo={P1}/>
                </Col>
                <Col>
                    <Featback
                        date={'11/12/2023'}
                        text={'“Une idée pertinente dans un contexte où la précarité étudiante est en hausse”'}
                        name={'Linda Mendy'}
                        rating={4}
                        photo={P2}/>
                </Col>
                <Col>
                    <Featback
                        date={'11/12/2023'}
                        text={'“Une fois ma startup créée, je suis sûre d’utiliser votre solution pour réaliser nos missions”'}
                        name={'Myriame Bourghel'}
                        rating={3}
                        photo={P3}/>
                </Col>
                <Col>
                    <Featback
                        date={'11/12/2023'}
                        text={'“Je me sens plus légitime pour ces missions grâce au format que vous proposez”'}
                        name={'Titouan Arnaud'}
                        rating={4}
                        photo={P4}/>
                </Col>
            </Row>
        </Container>
    );
}
