import "../style.css";
import { Container, Row, Col } from "react-bootstrap";
import FooterContainer from "./FooterComponents/FooterContainer";
import React from "react";
import BandeauPC from "../../../utils/images/bandeauPC.svg";
import BackgroundGradiant from "../../../utils/images/grad5.png";
import StartupLandingContainer from "./StartupLandingContainer";
import StartupBandeau from "../../../utils/images/StartupBandeau.png";
import Perso_2 from "../../../utils/images/perso_2.svg";
import {FeatbackContainer} from "./FeatbackComponents/FeatbackContainer";

export function StartupLandingPage() : JSX.Element {
    return (
        <>
            <img className="background-image Big-image Reset-margin-top"
                 style={{marginTop: '-10vh'}} id={'gradiant'} src={BackgroundGradiant}
                 alt="gradiant"/>
            <Row mb={12}>
                <Col>
                    <StartupLandingContainer></StartupLandingContainer>
                </Col>
            </Row>

                    <img style={{marginTop: '15vh', marginBottom: '20vh'}} id={'StartupBandeau'} src={StartupBandeau}
                         alt="StartupBandeau"/>


            <Row className='JobbeurNewTitleContainer'>
                <Col md={{ span: 6, offset: 1 }} className='JobbeurNewTitle-1'>
                    {`Ce qu’ils pensent de nous`}
                </Col>
                <Col>
                    <img className="background-image" style={{marginTop:"-10%"}} id={'Tel'} src={Perso_2} alt="perso_1" />
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 7, offset: 1 }}>
                    {'Votre avis compte pour nous !'}
                    <br/>
                    {'Jobbeur est à l’écoute des Startups du digital pour leur offrir l’opportunité de développer leur talent.'}
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 11, offset: 1 }}>
                    <FeatbackContainer></FeatbackContainer>
                </Col>
            </Row>

            <Row className='JobbeurNewTitleContainer' style={{marginBottom: '-1%', marginLeft: '1.8%'}}>
                <Col md={8} className='JobbeurFullBlueTitle'>
                    {'Créez vos missions dès maintenant'}
                </Col>
            </Row>
            <Row >
                <Col md={{span: 7, offset: 2}} className='JobbeurFullBlueTitle align-text-left mediumText'>
                    {'L’app web qui vous aide à faire grandir votre Startup.'}
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 3, offset: 2 }}>
                    <button className='JobbeurButtonSmall'>Je m’inscris</button>
                </Col>
            </Row>
            <img className="background-image Big-image" style={{marginTop: "-10%"}} id={'BandeauPC'} src={BandeauPC}
                 alt="PC"/>
            <Row mb={12}>
                <Col>
                    <FooterContainer></FooterContainer>
                </Col>
            </Row>
        </>

    );
}
