import {Col, Container, Row} from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import ProfileMain from "../Electron/ProfileMain";
import ProfileLinkCard from "../Electron/ProfileLinkCard";
import ProfileEditForm from "../Electron/ProfileEditForm";

export default function ProfileEditShow() {
    return (
        <>
            <div style={{ marginTop: '5vh', marginBottom: '2vh', marginLeft: '0.5vw' }}>
                <h1 style={{fontFamily: 'Nunito', fontWeight: '900'}}>Profile</h1>
            </div>
            <Container>
                <Row>
                <Col md={3}>
                <Row style={{ marginBottom: '1vh' }}>
                    <Col>
                        <ProfileMain />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ProfileLinkCard />
                    </Col>
                </Row>
                </Col>
                <Col>
                    <ProfileEditForm />
                </Col>
                </Row>
            </Container>
        </>
    );
}
