import "../style.css";
import { Container, Row, Col } from "react-bootstrap";
import LandingContainer from "./LandingContainer";
import FooterContainer from "./FooterComponents/FooterContainer";
import React from "react";
import BandeauPC from "../../../utils/images/bandeauPC.svg";
import BackgroundGradiant from "../../../utils/images/grad4.png";
import Perso_1 from "../../../utils/images/perso_1.svg";
import {RenderStars} from "../../../components/RenderStars";

enum UserType {
    Startup = 'startup',
    Etudiant = 'etudiant',
    Landing = 'landing',
}
interface LandingPageProps {
    handleChangeUserType: (type: UserType) => void;
}
export const LandingPage: React.FC<LandingPageProps> = ({ handleChangeUserType }) => {
    return (
        <>
            <img className="background-image Big-image Reset-margin-top"
                 style={{marginTop: '-10vh', height: '100vh'}} id={'gradiant'} src={BackgroundGradiant}
                 alt="gradiant"/>
            <div style={{height: "7vh"}}></div>
            <Row className='JobbeurNewTitleContainer'>
                <Col md={{span: 9, offset: 1}} className='JobbeurNewTitle'>
                    {`Boostez vos projets avec Jobbeur`}
                </Col>
                <Col>
                    <img className="background-image" style={{marginTop: '-5vh', marginLeft: '-17vw'}} id={'Tel'} src={Perso_1}
                         alt="perso_1"/>
                </Col>
            </Row>
            <Row className='JobbeurNewTitleContainer' style={{fontSize: '1.5rem', marginTop: '-5.5vh'}}>
                <Col md={{span: 7, offset: 1}}>
                    {'Jobbeur c’est l’opportunité de concrétiser vos ambitions,'}
                    <br/>
                    {'que vous soyez une entreprise en pleine croissance ou un étudiant/freelance en quête'}
                    <br/>
                    {'de missions stimulantes. '}
                </Col>
            </Row>
            <Row style={{marginTop: '15vh'}}>
                <Col md={{span: 3, offset: 1}} >
                    <button className='JobbeurButtonSmall' style={{width: '60%', background: '#446AD6'}}
                            onClick={() => handleChangeUserType(UserType.Startup)}>
                        Je suis une entreprise
                    </button>
                </Col>
                <Col md={3}>
                    <button className='JobbeurButtonSmall' style={{width: '60%', background: '#B678C6'}}
                            onClick={() => handleChangeUserType(UserType.Etudiant)}>
                        Je suis un étudiant</button>
                </Col>
            </Row>

        </>

    );
}
