import { Container, Row, Col } from "react-bootstrap";
import {IconType} from "react-icons";
import {ReactNode} from "react";

interface MissionCard {
    icon: ReactNode;
    text: string;
}

export function Mission(MissionCard: MissionCard)   {
    return (
        <Container className='MissionContainer'>
            <Row>
                <Col md={{ span: 3, offset: 3 }}>
                    {MissionCard.icon}
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="JobbeurTextSmall" style={{textAlign:"center", marginLeft:'-1.5vw'}}>
                        {MissionCard.text}
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
