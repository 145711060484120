import "./style.css";
import {BaseSign} from "./components/BaseSign";
import {Background} from "../../components/Background";
import {EtuRegister} from "./components/EtuRegister";
import {Register} from "./components/Register";
import Grad6 from "../../utils/images/grad6.png";
import React from "react";

export function Sign() : JSX.Element {
    return (
        <>
            <Register></Register>
            <img className="background-image Big-image Reset-margin-top"
                 style={{marginTop: '20vh', marginBottom: '25vh'}} id={'Grad6'} src={Grad6}
                 alt="Grad6"/>
        </>
    );
}
