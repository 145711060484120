import {FaStar} from "react-icons/fa";
import {CiStar} from "react-icons/ci";
import React from "react";

export const RenderStars = (rating: number) => {
    const stars = [];
    const fullStars = Math.floor(rating);

    for (let i = 1; i <= fullStars; i++) {
        stars.push(
            <FaStar key={i} color="rgb(0, 121, 255)" />
        );
    }

    const remainingStars = 5 - fullStars;
    for (let i = 1; i <= remainingStars; i++) {
        stars.push(
            <CiStar key={fullStars + i} />
        );
    }

    return stars;
};
