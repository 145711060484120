import React, { useState } from 'react';
import { Nav, Col } from 'react-bootstrap';
import { PiHouseLineLight } from 'react-icons/pi'; // Import your PiHouseLineLight icon if necessary
import { CgProfile } from 'react-icons/cg'; // Import your CgProfile icon if necessary
import { GoCommentDiscussion } from "react-icons/go";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { FiInfo } from "react-icons/fi";

interface VerticalNavProps {
    onSelect: (eventKey: any) => void;
    selectedNavItem: string;
}
const VerticalNav: React.FC<VerticalNavProps> = ({ onSelect, selectedNavItem }) => {


    return (
        <Nav variant="pills" activeKey={selectedNavItem} onSelect={onSelect} className="vertical-nav" defaultActiveKey="home">
            <Col>
                <Nav.Item>
                    <Nav.Link eventKey="home">
                        <PiHouseLineLight />
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="profile">
                        <CgProfile />
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="discussion">
                        <GoCommentDiscussion />
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="phone">
                        <IoPhonePortraitOutline />
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="info">
                        <FiInfo />
                    </Nav.Link>
                </Nav.Item>
            </Col>
        </Nav>
    );
}

export default VerticalNav;


