import {Container, Row, Col} from "react-bootstrap";
import {useEffect, useState} from "react";
import {supabase} from "../../../../app/supabaseClient";
import {Session} from "@supabase/supabase-js";

interface startupInterface {
    nb_mission: number,
    startup_name: string,
    ville: string,
    numero: number,
    code_postal: number,
    rue: string,
    pays: string,
    linkedin_link: string,
    site_link: string,
}
function ProfileEditForm() {
    const [dataStartup, setDataStartup] = useState<startupInterface>();
    const [session, setSession] = useState<Session | null>(null);

    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session);
        });

        const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session);
        });

        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const {data, error} = await supabase
                .from('startup')
                .select(
                    `nb_mission, startup_name, ville, numero, code_postal, rue, 
                    pays, linkedin_link, site_link`
                )
                .eq('id', session?.user?.id);

            if (error) {
                console.log(error)
            } else {
                console.log(data)
                setDataStartup(data[0])
                return data
            }
        };

        fetchData();
    }, [session]);
    return (
        <>
        <div>
        <h1 style={{fontFamily: 'Nunito', fontWeight: '900', marginLeft: '0.5vw'}}>Information générales</h1>
        </div>
        <Container>
            <Row style={{marginTop: '2vh'}}>
                <Col>
                    <div className="group">
                        <input id='startup_name' type="text" required style={{width: "70%"}} value={dataStartup?.startup_name}/>
                        <span className="highlight"></span>
                        <label className="JobbeurInputText">Nom de l'entreprise</label>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={2} >
                    <div className="group">
                        <input id='numero' type="text" required style={{width: "100%"}} value={dataStartup?.numero}/>
                        <span className="highlight"></span>
                        <label className="JobbeurInputText">Numéro</label>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="group">
                        <input id='rue' type="text" required style={{width: "100%"}} value={dataStartup?.rue}/>
                        <span className="highlight"></span>
                        <label className="JobbeurInputText">Rue</label>
                    </div>
                </Col>
                <Col md={3}>
                    <div className="group">
                        <input id='ville' type="text" required style={{width: "80%"}} value={dataStartup?.ville}/>
                        <span className="highlight"></span>
                        <label className="JobbeurInputText">Ville</label>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={3} >
                    <div className="group">
                        <input id='poste' type="text" required style={{width: "100%"}} value={dataStartup?.code_postal}/>
                        <span className="highlight"></span>
                        <label className="JobbeurInputText">Code postal</label>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="group">
                        <input id='pays' type="text" required style={{width: "90%"}} value={dataStartup?.pays}/>
                        <span className="highlight"></span>
                        <label className="JobbeurInputText">Pays</label>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={10} >
                    <div className="group">
                        <input id='linkedin_link' type="text" required style={{width: "84%"}} value={dataStartup?.linkedin_link}/>
                        <span className="highlight"></span>
                        <label className="JobbeurInputText">Lien Linkedin</label>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={10} >
                    <div className="group">
                        <input id='site_link' type="text" required style={{width: "84%"}} value={dataStartup?.site_link}/>
                        <span className="highlight"></span>
                        <label className="JobbeurInputText">Site internet</label>
                    </div>
                </Col>
            </Row>
        </Container>
        </>
    );

}

export default ProfileEditForm;
