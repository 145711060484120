import { Container } from "react-bootstrap";
import React, { useState } from "react";
import VerticalNav from "./VerticalNav";
import { Background } from "../../../components/Background";
import { MissionRendering } from "./Atom/MissionRendering";
import ProfileEditShow from "./Atom/ProfileEditShow";
import BackgroundGradiant from "../../../utils/images/grad7.png";


export function DashboardFront() {
    const [modalShow, setModalShow] = useState(false);
    const [selectedNavItem, setSelectedNavItem] = useState('home');

    const handleNavSelect = (eventKey: any) => {
        setSelectedNavItem(eventKey);
    };

    return (
        <>
            <VerticalNav onSelect={handleNavSelect} selectedNavItem={selectedNavItem}/>
            <img className="background-image Big-image Reset-margin-top"
                 style={{marginTop: '16vh', height: '100vh'}} id={'gradiant'} src={BackgroundGradiant}
                 alt="gradiant"/>
            <Container>
                {/* Render different components based on the selected navigation item */}
                {selectedNavItem === "home" && <MissionRendering/>}
                {selectedNavItem === "profile" && <ProfileEditShow/>}
                {/* Add more conditions for other navigation items */}
            </Container>
        </>
    );
}
