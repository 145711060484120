import {useState} from "react";
import { useStripe, useElements} from "@stripe/react-stripe-js";
import {PaymentElement} from "@stripe/react-stripe-js";

export default function CheckoutForm() {
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleSubmit = async (e: any) => {
        e.preventDefault()

        if (!stripe || !elements) {
            return;
        }

        setIsProcessing(true);

        const {error} = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: 'https://jobbeur.fr/dashboard',
            },
        });

        setIsProcessing(false);

    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement/>
            <button
                style={{marginTop: "20px", display: "flex", justifyContent: "end"}}
                type="submit"
                className="btn btn-primary"
                disabled={isProcessing}
            >
                {isProcessing ? "Paiement en cours" : "Effectuer le paiement"}
            </button>
        </form>
    );
}
