export const RouterPath = {
    home: '/',
    profile: '/profile',
    settings: '/settings',
    login: '/login',
    sign: '/sign',
    account: '/account',
    passwordRecovery: '/password-recovery',
    dashboard: '/dashboard',
};
