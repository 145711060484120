import "./style.css";
import { Container, Row, Col } from "react-bootstrap";
import LandingContainer from "./components/LandingContainer";
import {Background} from "../../components/Background";
import FooterContainer from "./components/FooterComponents/FooterContainer";
import BackgroundGradiant from "../../utils/images/gard2.png";
import React, {useState} from "react";
import BandeauPC from "../../utils/images/bandeauPC.svg";
import {StartupLandingPage} from "./components/StartupLandingPage";
import {EtudiantLandingPage} from "./components/EtudiantLandingPage";
import {LandingPage} from "./components/LandingPage";

enum UserType {
    Startup = 'startup',
    Etudiant = 'etudiant',
    Landing = 'landing',
}
export function Home() : JSX.Element {

    const [userType, setUserType] = useState<UserType>(UserType.Landing);

    // Fonction pour changer la valeur de userType
    const handleChangeUserType = (type: UserType) => {
        setUserType(type);
    };

    const renderUserType = () => {
        switch (userType) {
            case UserType.Startup:
                return <StartupLandingPage/>;
            case UserType.Etudiant:
                return <EtudiantLandingPage/>;
            case UserType.Landing:
                return <LandingPage handleChangeUserType={handleChangeUserType}/>;
            default:
                return <h1>Erreur</h1>;
        }
    }

    return (
        <>
            <Container className='GrandContainer'>
                {renderUserType()}
            </Container>


        </>

    );
}
