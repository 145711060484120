import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, {useEffect, useState} from "react";
import {Session} from "@supabase/supabase-js";
import {supabase} from "../../../../app/supabaseClient";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import {CgProfile} from "react-icons/cg";
import {Col, Row} from "react-bootstrap";
import {FaStar} from "react-icons/fa";
import {CiCalendar, CiStar} from "react-icons/ci";
import {RiMapPinLine} from "react-icons/ri";
import {TbCoinEuro} from "react-icons/tb";
import {useAppSelector} from "../../../../app/hooks";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";

interface profileInterface {
    role: string,
}

interface missionInterface {
    name: string,
    status_id: number,
    dure: string,
    nb_candidat: number,
    nb_like: number,
    difficulte: number,
    description: string,
    remuneration_total: number,
    city: string,
}

export default function ModalShowMission(props: any) {

    const [dataProfile, setDataProfile] = useState<profileInterface>();
    const [dataMission, setDataMission] = useState<missionInterface>();
    const [session, setSession] = useState<Session | null>(null);
    const role = useAppSelector((state) => state.user.role);

    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session);
        });

        const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session);
        });

        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const {data, error} = await supabase
                .from('profile')
                .select(
                    `role`
                )
                .eq('id', session?.user?.id);

            if (error) {
                console.log(error)
            } else {
                console.log(data)
                setDataProfile(data[0])
                return data
            }
        };

        fetchData();
    }, [session]);

    function isLoggedEtu() {
        if (role !== 'startup') {
            return <>
                    <div style={{width:'100%', display: 'flex', justifyContent: 'end', justifyItems: 'center'}}>
                        <Button onClick={() => postul()}>Postuler</Button>
                    </div>
                </>
        }
    }

    const postul = async () => {
        await supabase.rpc('insert_mission_etudiant', {
            profile_uuid: session?.user.id,
            mission_uuid: props.mission.id,
        });

        const { error } = await supabase
            .from('mission')
            .update({ status: 2 })
            .eq('id', props.mission.id)
    };


    const renderStars = (rating: number) => {
        const stars = [];
        const fullStars = Math.floor(rating);

        for (let i = 1; i <= fullStars; i++) {
            stars.push(
                <FaStar key={i} color="#0C66EB" />
            );
        }

        const remainingStars = 5 - fullStars;
        for (let i = 1; i <= remainingStars; i++) {
            stars.push(
                <CiStar key={fullStars + i} />
            );
        }

        return stars;
    };

    const getStatusStyle = (status: number) => {
        let textStyle = {};

        switch (status) {
            case 1:
                textStyle = {
                    color: 'darkgreen',
                    backgroundColor: 'lightgreen',
                    borderRadius: '25px',
                    padding: '8px',
                    display: 'inline-block',
                };
                break;
            case 2:
                textStyle = {
                    color: '#C60D0D',
                    backgroundColor: '#FF9A9A',
                    borderRadius: '25px',
                    padding: '8px',
                    display: 'inline-block',
                };
                break;

            default:
                // Style par défaut ou pour d'autres cas
                break;
        }

        return textStyle;
    };

    const displayRemuneration = (remuneration_total: number, remuneration_heure: number) => {
        if (remuneration_heure > 0) {
            return remuneration_heure + '€/h';
        } else if (remuneration_total > 0) {
            return remuneration_total + '€';
        }
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <Row md={12}>
                    <Col md={1}>
                        <CgProfile size={35} />
                    </Col>
                    <Col md={6}>
                        {props.mission.name}
                    </Col>
                    <Col md={{span:4, offset:1}}>
                        <RenderingPayement/>
                    </Col>
                </Row>
                <Row md={12} style={{marginBottom: '1vh'}}>
                    <Col md={{span:2, offset:1}}>
                        {props.mission.startup['startup_name']}
                    </Col>
                    <Col md={4}>
                        {renderStars(props.mission.difficulte)}
                    </Col>
                </Row>
                <Row md={12} style={{marginBottom: '3vh'}}>
                    <Col md={{span:2, offset:1}}>
                        <span style={getStatusStyle(props.mission.status_id!)}>
                            Disponible
                        </span>
                    </Col>
                </Row>
                <Row md={12}>
                        <Col md={11}>
                            <RiMapPinLine/> {props.mission.startup['numero']} {props.mission.startup['rue']}
                            {props.mission.startup['ville']} {props.mission.startup['code_postal']} {props.mission.startup['pays']}
                        </Col>
                </Row>
                <Row>
                    <Col md={11}>
                        <CiCalendar/> {props.mission.dure}
                    </Col>
                </Row>
                <Row style={{marginBottom: '3vh'}}>
                    <Col md={11}>
                        <TbCoinEuro/> {displayRemuneration(props.mission.remuneration_total!, props.mission.remuneration_heure!)}
                    </Col>
                </Row>

                    <FloatingLabel controlId="floatingTextarea2" label="Description">
                        <Form.Control
                            as="textarea"
                            value={props.mission.description}
                            placeholder="Description"
                            style={{ height: '100px' }}
                        />
                    </FloatingLabel>

                {/*<Row>
                    <Col md={{span:2, offset:10}} style={{ display: 'flex', justifyContent: 'center', justifyItems: 'end' }}>
                        {isLoggedEtu()}
                    </Col>
                </Row>*/}
                <br/>
                {isLoggedEtu()}
            </Modal.Body>

        </Modal>
    );
}

function MyVerticallyCenteredModal(props: any) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h1>Paiement</h1>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Payment/>
            </Modal.Body>
        </Modal>
    );
}

function RenderingPayement() {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <>
            <Button variant="primary" onClick={() => setModalShow(true)}>
                Paiement
            </Button>

            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}

function Payment(props: any) {
    const [clientSecret, setClientSecret] = useState('');
    const publishableKey = 'pk_test_51Ou9xyRppqsZII1hE3HPhv5x4u9mU7Nd8JULetJhdK3pwm7VHqf3ogmVq53QABPx2tYM2GuNnS5RSX6J3agTh6Uw00hldLiiJT';
    const privateKey = 'sk_test_51Ou9xyRppqsZII1hrGpKxio56U5K1DS0R6BJ4253ZnvytClzk1UfdyhRA3D612XnnvwNKkaGu4n6McqLIu7NSYn10073zIVJRm';
    const [stripePromise, setStripePromise] = useState<any>(loadStripe(publishableKey));

    useEffect(() => {
        const stripe = loadStripe(publishableKey);
/*        console.log('stripe');
        console.log(stripe);*/
        setStripePromise(stripe);
    }, []);

    useEffect(() => {
        if (stripePromise) {
            createPaymentIntent(stripePromise).then((clientSecret: string) => {
                setClientSecret(clientSecret);
            });
        }
    }, [stripePromise]);

    async function createPaymentIntent(stripePromise: any) {
        if (!stripePromise) return; // Vérifie si stripePromise est défini

        const stripeInstance = require("stripe")(privateKey, {
            apiVersion: '2022-08-01',
        })

        console.log(stripePromise);
        const stripe = await stripePromise;
        setStripePromise(stripe);
        console.log(stripe);
        const paymentIntent = await stripeInstance.paymentIntents.create({
            amount: 1099,
            currency: 'eur',
            automatic_payment_methods: {
                enabled: true,
            }
        });
        console.log(paymentIntent.client_secret);
        setClientSecret(paymentIntent.client_secret);
        return paymentIntent.client_secret;
    }

    return (
        <>
            {stripePromise && clientSecret &&
                (<Elements stripe={stripePromise} options={{clientSecret}}>
                    <CheckoutForm />
                </Elements>)}
        </>
    );
}

