import "../style.css";
import { Container, Row, Col } from "react-bootstrap";
import LandingContainer from "./LandingContainer";
import FooterContainer from "./FooterComponents/FooterContainer";
import React from "react";
import BandeauPC from "../../../utils/images/bandeauPC.svg";
import BackgroundGradiant from "../../../utils/images/grad5.png";

export function EtudiantLandingPage() : JSX.Element {
    return (
        <>
            <img className="background-image Big-image Reset-margin-top"
                 style={{marginTop: '-10vh'}} id={'gradiant'} src={BackgroundGradiant}
                 alt="gradiant"/>
            <Row mb={12}>
                <Col>
                    <LandingContainer></LandingContainer>
                </Col>
            </Row>
            <img className="background-image Big-image" style={{marginTop: "-10%"}} id={'BandeauPC'} src={BandeauPC}
                 alt="PC"/>
            <Row mb={12}>
                <Col>
                    <FooterContainer></FooterContainer>
                </Col>
            </Row>
        </>

    );
}
