import "./style.css";
import {DashboardFront} from "./components/DashboardFront";
import BackgroundGradiant from "../../utils/images/grad3.png";
import React from "react";

export function Dashboard() : JSX.Element {
    return (
        <>
            <DashboardFront></DashboardFront>
        </>
    );
}
