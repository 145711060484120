import { Container, Row, Col } from "react-bootstrap";
import {JobbeurDesci} from "./JobbeurDescri";


export default function FooterContainer()   {
    return (
        <Container className='FooterContainer'>
            <JobbeurDesci></JobbeurDesci>
        </Container>
    );
}
