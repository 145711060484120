import { Container, Row, Col, Button } from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import React, {useEffect, useState} from "react";
import {supabase} from "../../../../app/supabaseClient";
import {FaStar, FaHeart, FaSearch  } from "react-icons/fa";
import {CiStar, CiCalendar} from "react-icons/ci";
import { RiMapPinLine } from "react-icons/ri";
import { TbCoinEuro } from "react-icons/tb";
import Backdrop from "../Electron/Backdrop";
import NewMissionModal from "../Electron/NewMissionModal";
import ModalShowMission from "../Electron/ModalShowMission";
import {useAppSelector} from "../../../../app/hooks";

interface missionItem {
    id: string | null,
    name: string | null,
    created_at: string | null,
    startup_id: string | null,
    status_id: number | null,
    description: string | null,
    nb_candidat: number | null,
    nb_like: number | null,
    difficulte: number | null,
    dure: string | null,
    startup: {
        startup_name: string | null,
        ville: string | null,
        numero: string | null,
        rue: string | null,
        code_postal: string | null,
        pays: string | null,
    },
    remuneration_total: number | null,
    remuneration_heure: number | null,
}


interface missionEtudiantInterface {
    profile_uuid: string | null,
    mission_uuid: number | null,
    status: number | null,
}

const getStatusStyle = (status: number) => {
    let textStyle = {};

    switch (status) {
        case 1:
            textStyle = {
                color: 'darkgreen',
                backgroundColor: 'lightgreen',
                borderRadius: '25px',
                padding: '8px',
                display: 'inline-block',
            };
            break;
        case 2:
            textStyle = {
                color: '#C60D0D',
                backgroundColor: '#FF9A9A',
                borderRadius: '25px',
                padding: '8px',
                display: 'inline-block',
            };
            break;

        default:
            // Style par défaut ou pour d'autres cas
            break;
    }

    return textStyle;
};


const PinkHeartIcon = () => {
    const iconStyle = {
        color: 'E921BD',
    };

    return <FaHeart style={iconStyle} />;
};


const renderStars = (rating: number) => {
    const stars = [];
    const fullStars = Math.floor(rating);

    for (let i = 1; i <= fullStars; i++) {
        stars.push(
            <FaStar key={i} color="gold" />
        );
    }

    const remainingStars = 5 - fullStars;
    for (let i = 1; i <= remainingStars; i++) {
        stars.push(
            <CiStar key={fullStars + i} />
        );
    }

    return stars;
};

const FilterInput = () => {
    return (
        <Container className='filterDoubleInputContainer'>
            <Row>
                <Col md={8}>
                    <Row>
                        <Col md={1}>
                            <FaSearch />
                        </Col>
                        <Col md={9}>
                            <label htmlFor="filterInput" className="form-label">
                                Recherche
                            </label>
                            <input
                                type="text"
                                id="filterInputRecherche"
                                placeholder="Recherche"
                                className='filterDoubleInput'
                            />
                        </Col>
                    </Row>
                </Col>
                <Col md={3}>
                    <Row>
                        <Col md={1}>
                            <RiMapPinLine style={{alignContent:"center"}}/>
                        </Col>
                        <Col md={9}>
                            <label htmlFor="filterInput" className="form-label">
                                Localisation
                            </label>
                            <input
                                type="text"
                                id="filterInputLocalisation"
                                placeholder="Localisation"
                                className='filterDoubleInput'
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

const displayRemuneration = (remuneration_total: number, remuneration_heure: number) => {
    if (remuneration_heure > 0) {
        return remuneration_heure + '€/h';
    } else if (remuneration_total > 0) {
        return remuneration_total + '€';
    }
};

export function MissionRendering() {
    const role = useAppSelector((state) => state.user.role);
    const [modalShow, setModalShow] = useState(false);
    const [dataMission, setDataMission] = useState<any[]>([]);
    const [dataMissionEtudiant, setDataMissionEtudiant] = useState<missionEtudiantInterface>();
    const [modalMission, setModalMission] = useState<missionItem>({
        id: null,
        name: null,
        created_at: null,
        startup_id: null,
        status_id: null,
        description: null,
        nb_candidat: null,
        nb_like: null,
        difficulte: null,
        dure: null,
        startup: {
            startup_name: null,
            ville: null,
            numero: null,
            rue: null,
            code_postal: null,
            pays: null,
        },
        remuneration_total: null,
        remuneration_heure: null,
    });
    const [modalShowMission, setModalShowMission] = React.useState(false);
    const [nbEtuMis, setNbEtuMis] = useState<number>(0);;
    const renderAddMissionButton = (role: string | undefined) => {
        if (role === 'startup') {
            return <Button style={{width: "100%"}} className='JobbeurButtonSmall' onClick={() => setModalShow(true)}>
                Ajouter une mission
            </Button>
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const {data, error} = await supabase
                .from('mission')
                .select(
                    `id, name, created_at, startup_id, status_id, description, 
                    nb_candidat, nb_like, difficulte, dure, remuneration_total, remuneration_heure,
                        startup(
                        startup_name,
                        ville, numero, rue, code_postal, pays
                        )`
                );

            if (error) {
                console.log(error)
            } else {
                console.log(data)
                setDataMission(data)
                return data
            }
        };

        fetchData();
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            const { data, error } = await supabase
                .from('mission_etudiant')
                .select('profile_uuid, mission_uuid, status')
                .eq('mission_uuid', '112e1185-0d3f-4df7-a4bd-6d66ba47fbf7')

            if (error) {
                console.log(error)
            } else {
                console.log(data)
                setNbEtuMis(data.length)
                setDataMissionEtudiant(data[0])
                return data
            }
        };

        fetchData();
    }, [dataMission, modalMission, modalShow]);


    const renderIsPostuled = (missionId: string) => {
        console.log('test '+dataMissionEtudiant)
        if (dataMissionEtudiant) {
            if (dataMissionEtudiant.status === 1 && missionId === '112e1185-0d3f-4df7-a4bd-6d66ba47fbf7' && role === 'etudiant') {
                console.log('Postulé')
                return <div style={{width:'100%', display: 'flex', justifyContent: 'end', justifyItems: 'center'}}>
                    <Button variant="success" disabled>Postulé</Button>
                </div>
            }
        }
    };
    const renderNbEtuMis = (missionId: string | null) => {
        if (dataMissionEtudiant) {
            if (dataMissionEtudiant.status === 1 && missionId === '112e1185-0d3f-4df7-a4bd-6d66ba47fbf7') {
                return nbEtuMis
            }else {
                return '0'
            }
        } else {
            return '0'
        }
    };

    function onHandleModalShowMission(mission: missionItem) {
        console.log('test'+mission.name);
        setModalMission(mission);
        setModalShowMission(true);
    }


    const renderItem = ({mission}: { mission: missionItem }) => (
        <Col xs={12} md={4} className="mb-4">
            <Card style={{width: '21rem'}} key={mission.id}
                  onClick={() => onHandleModalShowMission(mission)}>
                <Card.Body>
                    <Row>
                        <Col md={10}>
                            <Card.Title>{mission.name}</Card.Title>
                        </Col>
                        <Col md={2}>
                            <PinkHeartIcon/> <span style={{color: "E921BD"}}>{mission.nb_like}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card.Subtitle className="mb-2 text-muted">{mission.startup['startup_name']}</Card.Subtitle>
                        </Col>
                        <Col md={{span: 4, offset: 4}}>
                            {renderStars(mission.difficulte!)}
                        </Col>
                    </Row>
                    <Card.Text>
                        <Row>
                            <Col>
              <span style={getStatusStyle(mission.status_id!)}>
                Disponible
              </span>
                            </Col>
                            <Col>
                                {renderIsPostuled(mission.id!)}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <RiMapPinLine/> {mission.startup['numero']} {mission.startup['rue']} {mission.startup['ville']} {mission.startup['code_postal']} {mission.startup['pays']}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <CiCalendar/> {mission.dure}
                            </Col>
                            <Col md={{span: 4, offset: 2}}>
                                <TbCoinEuro/> {displayRemuneration(mission.remuneration_total!, mission.remuneration_heure!)}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
              <span className='smallMissionText'>
                {renderNbEtuMis(mission.id)}/{mission.nb_candidat} Candidatures
              </span>
                            </Col>
                            <Col>
              <span className='smallMissionText'>
                Publiée il y a 5 jours
              </span>
                            </Col>
                        </Row>
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
    );

    return (
        <>
        <Container>
            <Row>
                <FilterInput/>
            </Row>
            <Row>
                <Col md={{span: 4, offset: 4}}>
                    {renderAddMissionButton(role)}
                </Col>
            </Row>
            <br/>
            <Row>
                <Col>
                    <Row style={{flex: "1"}}>
                        <ModalShowMission
                            mission={modalMission}
                            show={modalShowMission}
                            onHide={() => setModalShowMission(false)}
                        />
                        <NewMissionModal
                            show={modalShow}
                            dialogClassName="modal-90w"
                            onHide={() => setModalShow(false)}
                        />
                        {dataMission
                            .slice(0, Math.ceil(dataMission.length / 3) * 3)
                            .map((mission) => (
                            // Passer la mission directement comme argument
                                renderItem({mission})
                            ))}
                    </Row>
                </Col>
            </Row>
            <Backdrop/>
        </Container>
        </>
    );
}
