import { useState, useEffect } from 'react'
import { createClient } from '@supabase/supabase-js'
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import {supabase} from "../../../app/supabaseClient";
import EntOrEdut from "./EntOrEduComponents/EntOrEdu";
import {Container, Row} from "react-bootstrap";


export function Register() {
    const [session, setSession] = useState(null)

    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
            // @ts-ignore
            return setSession(session);
        })

        const {
            data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, session) => {
            // @ts-ignore
            return setSession(session);
        })

        return () => subscription.unsubscribe()
    }, [])

    if (!session) {
        return (
            <>
                <Container style={{display: "flex", justifyItems:'center',
                    justifyContent:'center', marginTop:'20vh', width:'25vw'}}>
                    <Row md={12}>
                        <Auth supabaseClient={supabase} appearance={{ theme: ThemeSupa }} />
                    </Row>
                </Container>
            </>
        )
    }
    else {
        console.log(session)
        return (<EntOrEdut session={session}/>)
    }
}
