import "../../style.css";
import React, { useState } from 'react';
import {EtuRegister} from "../EtuRegister";
import {EntRegister} from "../EntRegister";
import {Col, Container, Row} from "react-bootstrap";
import {bool} from "yup";

interface EntOrEdutProps {
    session: {
        user: any; // Adjust the type according to your Supabase session structure
        access_token: string; // Adjust the type according to your Supabase session structure
        // Add other properties as needed
    };
}

const EntOrEdu: React.FC<EntOrEdutProps> = ({session}) => {
    // État pour stocker la valeur du bouton de commutation
    const [switchValue, setSwitchValue] = useState<boolean>(false);

    // Fonction appelée lors du changement de la valeur du bouton de commutation
    const handleSwitchChange = (type: boolean) => {
        setSwitchValue(type);
    };

    return (
        < >

            <Row className="registerCenterFormTest" style={{marginTop:"-80vh"}}>
                <Col md={{span: 5, offset: 2}} >
                    <button className='JobbeurButtonSmall' style={{width: '60%', background: '#446AD6'}}
                            onClick={() => handleSwitchChange(true)}>
                        Startup
                    </button>
                </Col>
                <Col md={5}>
                    <button className='JobbeurButtonSmall' style={{width: '60%', background: '#B678C6'}}
                            onClick={() => handleSwitchChange(false)}>
                        Étudiant</button>
                </Col>
            </Row>
                {switchValue ?
                    <EntRegister session={session} /> :
                    <EtuRegister session={session} />}

        </>
    );
};

export default EntOrEdu;
