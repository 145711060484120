import { Container, Row, Col } from "react-bootstrap";
import '../style.css';
import { CenterForm } from "./CenterForm";
import back from '../../../utils/images/back.png';

export function LoginScreen()   {
    return (
        <Container>
            <Row xs={6} md={12}>
                <Col xs={4} md={10}>
                    <CenterForm></CenterForm>
                </Col>
            </Row>
            <img src={back} alt="back" className="back_blue_graph"/>;
        </Container>
    );
}
