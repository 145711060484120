import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {Col, Container, Row} from "react-bootstrap";

export function CenterForm() {
    return (
            <Container className="centerForm">
                <Form>
                <Row xs={6} lg={12}>
                    <Col lg={{ span: 8, offset: 2 }}>
                        <div className="JobbeurText"  style={{textAlign:"center", color:"#000"}}>
                            Connectez-vous
                        </div>
                    </Col>
                </Row>
                <Row xs={6} lg={12}>
                    <Col xs={4}  lg={{span:10, offset:1}}>
                        <div className="group">
                            <input id='mailInput' type="text" required style={{width:"100%"}}/>
                            <span className="highlight"></span>
                            <label className="JobbeurInputText">Identifiant</label>
                        </div>
                    </Col>
                </Row>
                <Row xs={6} lg={12}>
                    <Col xs={4}  lg={{span:10, offset:1}}>
                        <div className="group">
                            <input id='nameInput' type="text" required style={{width:"100%"}}/>
                            <span className="highlight"></span>
                            <label className="JobbeurInputText" style={{fontWeight:"1.7rem"}}>Nom</label>
                        </div>
                    </Col>
                </Row>
                <Row xs={6} lg={12}>
                    <Col xs={4}  lg={{span:6, offset:7}}>
                        <a href="/passwordRecovery">
                            Réinitialiser mon mot de passe
                        </a>
                    </Col>
                </Row>
                <Row xs={6} lg={12}>
                    <Col xs={4}  lg={{span:6, offset:5}}>
                        <button type="submit" className="JobbeurButtonSmall">Valider</button>
                    </Col>
                </Row>
        </Form>
                <Row xs={6} lg={12}>
                    <Col xs={4}  lg={{span:8, offset:3}}>
                        <a href="/sign">
                            <button className="JobbeurButtonClassicSmall">Créer un compte Etudiant</button>
                        </a>
                    </Col>
                </Row>
                <Row xs={6} lg={12}>
                    <Col xs={4}  lg={{span:8, offset:3}}>
                        <button className="JobbeurButtonReversSmall" style={{textAlign:"center"}}>Créer un compte Entreprise</button>
                    </Col>
                </Row>
            </Container>

    );
}
