import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {Col, Container, FloatingLabel, Row} from "react-bootstrap";
import {BaseSign} from "./BaseSign";
import React, {useState} from "react";
import {FieldValues, useForm} from "react-hook-form";
import {supabase} from "../../../app/supabaseClient";
import { Link } from 'react-router-dom';

interface Session {
    user: {
        id: number;
        email: string;
    };
}

interface EtuRegisterProps {
    session: Session;
}
export function EntRegister({session}: EtuRegisterProps) : JSX.Element {

    const [civility, setCivility] = useState<string>('');
    const [nom, setNom] = useState<string>('');
    const [prenom, setPrenom] = useState<string>('');
    const [email, setEmail] = useState<string>(session.user.email || '');
    const [tel, setTel] = useState<string>('');
    const [ville, setVille] = useState<string>('');
    const [poste, setPoste] = useState<bigint>();
    const [domaine, setDomaine] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const Register = async (
        name: string,
    ) => {
        await supabase.rpc('insert_ent_profile', {
            id: session.user.id,
            username: name,
        });
    };

    return (
        <>
            <Container className="registerCenterForm" style={{marginTop:"-34vh"}}>
                <Form>
                    <Row xs={6} lg={12}>
                        <Col lg={{ span: 10, offset: 1 }}>
                            <div className="JobbeurText"  style={{textAlign:"center", color:"#000"}}>
                                Start up
                            </div>
                        </Col>
                    </Row>
                    <Row xs={6} lg={12}>
                        <Col xs={4}  lg={{span:10, offset:1}}>
                            <div className="group">
                                <input id='nomInput' type="text" required style={{width:"100%"}}
                                       value={nom}
                                       onChange={(e) => setNom(e.target.value)}/>
                                <span className="highlight"></span>
                                <label className="JobbeurInputText">Nom de la structure</label>
                            </div>
                        </Col>
                    </Row>
                    <Row xs={6} lg={12}>
                        <Col xs={4}  lg={{span:10, offset:1}}>
                            <div className="group">
                                <input id='emailInput' type="text" required style={{width:"100%"}}
                                       value={email}
                                       onChange={(e) => setEmail(e.target.value)}/>
                                <span className="highlight"></span>
                                <label className="JobbeurInputText">Email</label>
                            </div>
                        </Col>
                    </Row>
                    <Row xs={6} lg={12}>
                        <Col xs={4}  lg={{span:10, offset:1}}>
                            <div className="group">
                                <input id='telInput' type="text" required style={{width:"100%"}}
                                       value={tel}
                                       onChange={(e) => setTel(e.target.value)}/>
                                <span className="highlight"></span>
                                <label className="JobbeurInputText">N°téléphone</label>
                            </div>
                        </Col>
                    </Row>
                    <Row xs={6} lg={12}>
                        <Col lg={{span:6, offset:1}}>
                            <div className="group">
                                <input id='cityInput' type="text" required style={{width:"100%"}}
                                       value={ville}
                                       onChange={(e) => setVille(e.target.value)}/>
                                <span className="highlight"></span>
                                <label className="JobbeurInputText">Ville</label>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="group">
                                <input
                                    id='posteInput'
                                    type="text"
                                    required
                                    style={{width:"100%"}}
                                    value={poste === undefined ? '' : poste.toString()}  // Convert bigint to string for input value
                                    onChange={(e) => {
                                        const inputValue = e.target.value.trim();
                                        setPoste(inputValue === '' ? undefined : BigInt(inputValue) as bigint);
                                    }}
                                />
                                <span className="highlight"></span>
                                <label className="JobbeurInputText">Code postal</label>
                            </div>
                        </Col>
                    </Row>
                    <Row xs={6} lg={12}>
                        <Col xs={4}  lg={{span:10, offset:1}}>
                            <div className="group">
                                <input id='pwdInput' type="password" required style={{width:"100%"}}
                                       value={password}
                                       onChange={(e) => setPassword(e.target.value)}/>
                                <span className="highlight"></span>
                                <label className="JobbeurInputText">Mot de passe</label>
                            </div>
                        </Col>
                    </Row>
                    {/*                    <Row xs={6} lg={12}>
                        <Col xs={4}  lg={{span:10, offset:1}}>
                            <div className="group">
                                <input id='pwdInput' type="password" required style={{width:"100%"}}/>
                                <span className="highlight"></span>
                                <label className="JobbeurInputText">Mot de passe</label>
                            </div>
                        </Col>
                    </Row>*/}
                </Form>
                <Row xs={6} lg={12}>
                    <Col xs={6}  lg={{span:8, offset:4}}>
                        <Link to="/dashboard">
                            <button className="JobbeurButtonClassicSmall" onClick={() => Register(nom)}>Créer un compte Start up!!</button>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
