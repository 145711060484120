import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import {FaStar} from "react-icons/fa";
import {CiStar} from "react-icons/ci";
import { CgProfile } from "react-icons/cg";
import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import { GrLinkedin } from "react-icons/gr";
import { GoLink } from "react-icons/go";
import {Session} from "@supabase/supabase-js";
import {supabase} from "../../../../app/supabaseClient";

interface startupInterface {
    nb_mission: number,
    startup_name: string,
    ville: string,
    numero: number,
    code_postal: number,
    rue: string,
    pays: string,
    linkedin_link: string,
    site_link: string,
}

function ProfileLinkCard() {
    const [dataStartup, setDataStartup] = useState<startupInterface>();
    const [session, setSession] = useState<Session | null>(null);

    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session);
        });

        const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session);
        });

        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const {data, error} = await supabase
                .from('startup')
                .select(
                    `nb_mission, startup_name, ville, numero, code_postal, rue, 
                    pays, linkedin_link, site_link`
                )
                .eq('id', session?.user?.id);

            if (error) {
                console.log(error)
            } else {
                console.log(data)
                setDataStartup(data[0])
                return data
            }
        };

        fetchData();
    }, [session]);

    const handleLinkedInClick = (link: string) => {
        window.location.href = link; // Redirect to LinkedIn
    };

    return (
        <Card style={{ width: '18rem' }}>
            {/*<Card.Img variant="top" src="holder.js/100px180?text=Image cap" />*/}
            <Card.Body className={'jobbeurGradientBackground'}>
                <Card.Title style={{ textAlign: 'center' }} className={'bigWhiteText'}>Liens</Card.Title>

                <Card.Text style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', alignItems:'center' }}>
                    <div className={'profileLinkButton'} onClick={() => handleLinkedInClick(dataStartup?.linkedin_link!)}>
                        <span style={{color: '#0C87F2'}} className={'profileLinkButtonText'}>
                            Linkedin
                        </span>
                        <GrLinkedin style={{color: '#0C87F2'}} className={'profileLinkButtonIcon'}/>
                    </div>
                </Card.Text>
                <Card.Text style={{
                    display: 'flex',
                    justifyContent: 'center',
                    justifyItems: 'center',
                    alignItems: 'center',
                    marginBottom: '5%' }}>
                    <div className={'profileLinkButton'} onClick={() => handleLinkedInClick(dataStartup?.site_link!)}>
                        <span style={{ color: '#BF78BB' }} className={'profileLinkButtonText'}>
                            Site Web
                        </span>
                        <GoLink style={{color: '#BF78BB'}} className={'profileLinkButtonIcon'}/>
                    </div>
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default ProfileLinkCard;
